export const AdsenseHelper = {
	load(webLocale: string, resolve: () => void) {
		// adsense vendor
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.defer = true;
		script.setAttribute('crossorigin', 'anonymous');
		script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3179068936475881';
		document.body.appendChild(script);

		((window.adsbygoogle = window.adsbygoogle || []) as any).onload = resolve;
	},
};

const trafficReferrers = [
	// Search Engines
	/https:\/\/www\.google/g,
	/https:\/\/www\.bing/g,
	/https:\/\/duckduckgo/g,
	/https:\/\/[a-zA-z]{2}\.search\.yahoo/g,
	// Social Media
	/https:\/\/l\.instagram/g,
	/https:\/\/www\.facebook/g,
	/https:\/\/t\.co/g,
	/https:\/\/www\.reddit/g,
];

export const TrafficHelper = {
	isCampaignTraffic() {
		if (process.server) {
			return false;
		}
		return ['campaign_survey', 'uct_cs', 'uct_n', 'uct_b', 'sim_locale'].some(
			param => location.search.indexOf(param) !== -1
		);
	},

	isSeoTraffic() {
		return process.server ? false : trafficReferrers.some(referrer => referrer.test(document.referrer));
	},
};

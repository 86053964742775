export const numberToCompactString = (num: number, digits: number): string => {
	const lookup = [
		{ value: 1, symbol: '' },
		{ value: 1e3, symbol: 'k' },
		{ value: 1e6, symbol: 'm' },
		{ value: 1e9, symbol: 'bn' },
	];
	const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	const item = lookup
		.slice()
		.reverse()
		.find(l => num >= l.value);

	if (item) {
		const shortened = (num / item.value).toFixed(digits).replace(rx, '$1');
		return shortened + item.symbol;
	}
	return '0';
};

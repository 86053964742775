
import {
	SetInWatchlistDocument,
	SetInWatchlistMutation,
	SetInWatchlistMutationVariables,
} from '@/graphql/mutation/SetInWatchlist.mutation';
import { SnowplowContext, SnowplowTitleContextGraphql } from '@/helpers/tracking/providers';
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { ObjectType } from '@/@types/graphql-types';
import { preventNonSignedInAction } from '@/helpers/prevent-non-signed-in-action-helper';
import { addTitleToListToast, removeTitleFromListToast } from '@/helpers/toast/toast-helper';
import { TrackingHelper, TrackingListEventPayload } from '@/helpers/tracking/tracking-helper';
import { TitleListName } from '@/interfaces/responses/title-list';

import { ImpressionTrackingEvents } from '@/enums/events';
import { ListMutationTitleDetailParam, TitleQuickActionPayload } from '@/helpers/providers/title-actions-provider';
import type { SponsoredAdFragment } from '@/pages/graphql/fragments/SponsoredAd.fragment';

const Language = namespace('language');
const User = namespace('user');

@Component
export default class TitleWatchlistMutationMixin extends Vue {
	@Prop({ default: undefined }) declare sponsoredAd: SponsoredAdFragment | undefined;

	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];

	@Language.Getter country: string;

	@User.Getter isLoggedIn: boolean;

	get isSponsoredRecommendationCohort() {
		return this.sponsoredAd && !this.sponsoredAd?.holdoutGroup;
	}

	get sponsoredRecommendationPackageId() {
		return this.sponsoredAd?.campaign?.watchNowOffer.package.packageId;
	}

	@Watch('activeRoute')
	async mixin_onRouteChange() {
		if (process.client) localStorage.removeItem('notifyCallback');
	}

	// IMPORTANT: implement this with a @Watch('isLoggedIn') in the component that requires a login callback. Example in BuyBox.vue
	async mixin_onIsLoggedInChange(
		titleId: string,
		details: { objectId: number; objectType: ObjectType; title: string },
		isTitleInWatchlist: boolean,
		source: string
	) {
		if (process.client) {
			if (this.isLoggedIn && localStorage.getItem('notifyCallback') === titleId.toString()) {
				this.mixin_setInWatchlist(titleId, isTitleInWatchlist, details, { source });

				localStorage.removeItem('notifyCallback');
			}
		}
	}

	async mixin_setInWatchlist(
		titleId: string,
		isTitleInList: boolean,
		titleDetails: ListMutationTitleDetailParam,
		trackingPayload?: {
			source?: string;
			property?: string;
		}
	) {
		if (process.client && !this.isLoggedIn) localStorage.setItem('notifyCallback', titleId.toString());

		const preventAction = await preventNonSignedInAction();

		if (preventAction) {
			return null;
		}

		// Currently unsupported
		if (titleDetails.objectType === ObjectType.ShowSeason) return;

		return this.$apollo
			.mutate<SetInWatchlistMutation, SetInWatchlistMutationVariables>({
				mutation: SetInWatchlistDocument,
				variables: {
					input: {
						id: titleId,
						state: !isTitleInList,
					},
					country: this.country,
				},
				update: () => {
					const source = trackingPayload?.source ?? '';

					const contexts = [...this.additionalContexts];
					const hasTitleContext = contexts.find(context => context.__name.includes('title'));

					if (!hasTitleContext) {
						contexts.push(
							new SnowplowTitleContextGraphql(
								titleDetails.objectId,
								titleDetails.objectType,
								null,
								null,
								titleDetails.contentType
							)
						);
					}

					if (trackingPayload) {
						const payload: TrackingListEventPayload = {
							action: source,
						};

						if (trackingPayload?.property) {
							payload.property = trackingPayload.property;
						}

						isTitleInList
							? this.trackWatchlistRemoveEvent(payload, contexts)
							: this.trackWatchlistAddEvent(payload, contexts);
					}

					if (
						!!this.sponsoredRecommendationPackageId &&
						this.sponsoredAd?.campaign?.node.nodeId === titleId &&
						!isTitleInList
					) {
						TrackingHelper.trackEvent(
							ImpressionTrackingEvents.SPONSORED_RECOMMENDATIONS,
							{
								action: 'watchlist_clicked',
								label: `${titleId}_${this.sponsoredRecommendationPackageId}`,
								property: this.isSponsoredRecommendationCohort ? 'ad' : 'poster',
								value: this.isSponsoredRecommendationCohort ? 1 : 0,
							},
							contexts
						);
					}

					if (!isTitleInList) {
						addTitleToListToast(TitleListName.WATCHLIST, titleDetails, () =>
							this.mixin_setInWatchlist(titleId, true, titleDetails, trackingPayload)
						);
					} else {
						removeTitleFromListToast(TitleListName.WATCHLIST, titleDetails);
					}
				},
			})
			.then(data => {
				const payload: TitleQuickActionPayload = {
					titleObjectId: titleDetails.objectId,
					type: TitleListName.WATCHLIST,
					isTitleInList: isTitleInList,
					objectType: titleDetails.objectType,
				};
				this.$emit('title-quick-action', payload);

				return data;
			});
	}

	trackWatchlistAddEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.WATCHLIST, 'add', payload, contexts);
	}

	trackWatchlistRemoveEvent(payload: TrackingListEventPayload, contexts: SnowplowContext[]) {
		TrackingHelper.trackListEvent(TitleListName.WATCHLIST, 'remove', payload, contexts);
	}
}

import { WEB_LOCALES } from '@/constants/web-locales.constant';
import { WebLocale } from '@/enums/web-locale';
import { getSlotPath, loadAdYieldlove, yieldloveWebLocales } from '@/helpers/ad/yieldlove-helper';
import { ReadyType } from '@/stores/ready.store';
import Vue from 'vue';
import { AdsenseHelper } from './adsense-helper';
import { hasUCConsentBanner } from '../tracking';
import { loadGAM } from './gam-helper';

export const enum AdProvider {
	YIELDLOVE = 'yieldlove',
	ADSENSE = 'adsense',
	GAM = 'GAM',
}

export const AdProviderLocales: { [key: string]: AdProvider } = Object.keys(WEB_LOCALES).reduce((root, webLocale) => {
	(root as any)[webLocale] = getProviderByCountry(webLocale as WebLocale);
	return root;
}, {} as Record<keyof typeof WEB_LOCALES, AdProvider>);

export const adSlots = [
	// {
	// 	name: 'jw_{WEBLOCALE}_top',
	// 	path: '/21679119891/jw_{WEBLOCALE}_top',
	// 	sizes: [
	// 		{ x: 320, y: 50, breakpoint: 310, adsenseSlot: 5594148909 },
	// 		{ x: 728, y: 90, breakpoint: 825, adsenseSlot: 2516381812 },
	// 	],
	// },
	{
		name: 'jw_{WEBLOCALE}_inlist_1',
		path: '/21679119891/jw_{WEBLOCALE}_inlist_1',
		gamPath: '/21679119891/new_inlist_1',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_inlist_2',
		path: '/21679119891/jw_{WEBLOCALE}_inlist_2',
		gamPath: '/21679119891/new_inlist_2',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_inlist_3',
		path: '/21679119891/jw_{WEBLOCALE}_inlist_3',
		gamPath: '/21679119891/new_inlist_3',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_under_detail_info',
		path: '/21679119891/jw_{WEBLOCALE}_under_detail_info',
		gamPath: '/21679119891/title_under_detail_info',
		sizes: [{ x: 300, y: 600, breakpoint: 992, adsenseSlot: 9468572974 }],
	},
	{
		name: 'jw_{WEBLOCALE}_under_buybox',
		path: '/21679119891/jw_{WEBLOCALE}_under_buybox',
		gamPath: '/21679119891/title_under_buybox',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 728, y: 90, breakpoint: 1130, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_bottom_page',
		path: '/21679119891/jw_{WEBLOCALE}_bottom_page',
		gamPath: '/21679119891/title_bottom_page',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 728, y: 90, breakpoint: 1130, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_under_offers',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_under_offers',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 728, y: 90, breakpoint: 1130, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_under_details',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_under_details',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 728, y: 90, breakpoint: 1130, adsenseSlot: 2516381812 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_1',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_1',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_2',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_2',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_3',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_3',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_4',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_4',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_5',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_5',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_6',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_6',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
	{
		name: 'jw_{WEBLOCALE}_sport_inlist_7',
		// path: '/21679119891/jw_{WEBLOCALE}_sport_inlist_7',
		sizes: [
			{ x: 300, y: 250, breakpoint: 310, adsenseSlot: 2488461659 },
			{ x: 970, y: 250, breakpoint: 1106, adsenseSlot: 1566723844 },
		],
	},
];

function getProviderByCountry(webLocale: WebLocale): AdProvider {
	if (yieldloveWebLocales.includes(webLocale)) {
		return AdProvider.YIELDLOVE;
	}
	if ([WebLocale.uk, WebLocale.fr, WebLocale.es, WebLocale.de, WebLocale.it].includes(webLocale)) {
		return AdProvider.GAM;
	}
	return AdProvider.ADSENSE;
}

// Declare Google Tag (DFP) and YieldLove Variables in Window object.
declare global {
	interface Window {
		// dfp
		googletag: {
			cmd: (() => void)[];
			pubads: any;
			defineSlot: any;
			enableServices: any;
			display: any;
		};
		// yieldlove
		YLHH: {
			bidder: any;
		};
	}
}

const ready$: Record<string, null | Promise<void>> = {};

const getAdProvider = (webLocale: WebLocale, isSport: boolean) => {
	if (isSport) {
		return AdProvider.ADSENSE;
	}
	return AdProviderLocales[webLocale];
};

const whenReady = async (webLocale: WebLocale, adProviderSelected: AdProvider) => {
	if (!global.VUE_APP_TESTS_E2E && hasUCConsentBanner(webLocale)) {
		// note: non-UC countries (regarding consent) should load the ads immediately.
		// that's why only UC countries will wait for `CONSENT_PARTNERS_ANSWERED`.
		await Vue.$jw.ready?.waitFor(ReadyType.CONSENT_PARTNERS_ANSWERED);
	}

	if (!ready$[adProviderSelected]) {
		switch (adProviderSelected) {
			case AdProvider.YIELDLOVE:
				ready$[AdProvider.YIELDLOVE] = new Promise<void>(
					async (resolve, reject) => await loadAdYieldlove(webLocale, resolve, reject)
				);
				break;
			case AdProvider.ADSENSE:
				ready$[AdProvider.ADSENSE] = new Promise<void>((resolve, reject) =>
					AdsenseHelper.load(webLocale, resolve)
				);
				break;
			case AdProvider.GAM:
				ready$[AdProvider.GAM] = new Promise<void>((resolve, reject) => loadGAM(webLocale, resolve));
				break;
		}
	}
	return ready$[adProviderSelected];
};

const loadAd = async (
	webLocale: WebLocale,
	slotId: string,
	adProviderSelected: AdProvider,
	onSlotReceived?: (filled: boolean) => void
) => {
	try {
		await whenReady(webLocale, adProviderSelected);

		switch (adProviderSelected) {
			case AdProvider.YIELDLOVE:
				if (window.YLHH && window.YLHH.bidder) {
					window.googletag.cmd.push(() => {
						window.YLHH.bidder.startAuction(getSlotPath(webLocale, `/21679119891/${slotId}`));

						window.googletag.cmd.push(function () {
							window.googletag.display(slotId);
						});

						window.googletag.pubads().addEventListener('slotResponseReceived', (evt: any) => {
							const { slot } = evt;
							if (slotId === slot.getSlotElementId()) {
								onSlotReceived?.(slot.getResponseInformation() !== null);
							}
						});
					});
				} else {
					import('@/helpers/sentry-helper').then(({ captureMessageForSentry }) => {
						captureMessageForSentry(`[ad-helper.ts YIELDLOVE]: window.YLHH.bidder is undefined`);
					});
				}

				break;
			case AdProvider.ADSENSE:
				(window.adsbygoogle = window.adsbygoogle || []).push({});
				break;
			case AdProvider.GAM:
				window.googletag.cmd.push(function () {
					window.googletag.display(slotId);
				});
				break;
			default:
				break;
		}
	} catch (error) {
		// TODO: Track AD loading failing if needed
	}
};

const removeAd = async (webLocale: WebLocale, slotId: string, adProviderSelected: AdProvider) => {
	await whenReady(webLocale, adProviderSelected);

	switch (adProviderSelected) {
		case AdProvider.YIELDLOVE:
			window.googletag.cmd.push(function () {
				window.googletag.pubads().clear([slotId]);
			});
			break;
	}
};

export { getAdProvider, loadAd, removeAd, whenReady };

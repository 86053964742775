import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetGuideArticlesQueryVariables = Types.Exact<{
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	first: Types.Scalars['Int']['input'];
	offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
	filter?: Types.ArticleFilter;
	profile?: Types.InputMaybe<Types.BackdropProfile>;
	format?: Types.InputMaybe<Types.ImageFormat>;
}>;

export type GetGuideArticlesQuery = {
	__typename?: 'Query';
	articles: {
		__typename?: 'ArticlesConnection';
		totalCount: number;
		edges?: Array<{
			__typename?: 'ArticlesEdge';
			node: {
				__typename?: 'Article';
				id: string;
				content: {
					__typename?: 'ArticleContent';
					articleType: Types.ArticleType;
					title?: string | null;
					subtitle?: string | null;
					paragraph1?: string | null;
					fullPath: string;
					mainImageEntityBackdrop?: { __typename?: 'Backdrop'; backdropUrl: string } | null;
				};
				mainObjects: Array<{
					__typename?: 'ArticleLinkedObject';
					node:
						| { __typename: 'Article'; id: string }
						| { __typename: 'Author'; id: string }
						| { __typename: 'Bundle'; id: string }
						| { __typename: 'Device'; id: string }
						| { __typename: 'Episode'; id: string }
						| {
								__typename: 'GenericTitleList';
								id: string;
								titles: {
									__typename?: 'GenericTitleListConnection';
									edges?: Array<{
										__typename?: 'GenericTitleListEdge';
										node:
											| {
													__typename?: 'Movie';
													id: string;
													content: {
														__typename?: 'MovieContent';
														backdrops: Array<{
															__typename?: 'Backdrop';
															backdropUrl: string;
														}>;
													};
											  }
											| {
													__typename?: 'Show';
													id: string;
													content: {
														__typename?: 'ShowContent';
														backdrops: Array<{
															__typename?: 'Backdrop';
															backdropUrl: string;
														}>;
													};
											  };
									}> | null;
								};
						  }
						| { __typename: 'Genre'; id: string }
						| {
								__typename: 'Movie';
								id: string;
								content: {
									__typename?: 'MovieContent';
									backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
								};
						  }
						| { __typename: 'MultiStepSportEvent'; id: string }
						| { __typename: 'Offer'; id: string }
						| { __typename: 'Package'; id: string }
						| { __typename: 'Page'; id: string }
						| { __typename: 'Person'; id: string }
						| {
								__typename: 'Season';
								id: string;
								content: {
									__typename?: 'SeasonContent';
									backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
								};
						  }
						| {
								__typename: 'Show';
								id: string;
								content: {
									__typename?: 'ShowContent';
									backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
								};
						  }
						| { __typename: 'SingleStepSportEvent'; id: string }
						| { __typename: 'SportCompetitionV2'; id: string }
						| { __typename: 'SportCompetitorV2'; id: string }
						| { __typename: 'Url'; id: string };
				}>;
			};
		}> | null;
	};
};

export type GuideEntryFragment = {
	__typename?: 'Article';
	id: string;
	content: {
		__typename?: 'ArticleContent';
		articleType: Types.ArticleType;
		title?: string | null;
		subtitle?: string | null;
		paragraph1?: string | null;
		fullPath: string;
		mainImageEntityBackdrop?: { __typename?: 'Backdrop'; backdropUrl: string } | null;
	};
	mainObjects: Array<{
		__typename?: 'ArticleLinkedObject';
		node:
			| { __typename: 'Article'; id: string }
			| { __typename: 'Author'; id: string }
			| { __typename: 'Bundle'; id: string }
			| { __typename: 'Device'; id: string }
			| { __typename: 'Episode'; id: string }
			| {
					__typename: 'GenericTitleList';
					id: string;
					titles: {
						__typename?: 'GenericTitleListConnection';
						edges?: Array<{
							__typename?: 'GenericTitleListEdge';
							node:
								| {
										__typename?: 'Movie';
										id: string;
										content: {
											__typename?: 'MovieContent';
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
								  }
								| {
										__typename?: 'Show';
										id: string;
										content: {
											__typename?: 'ShowContent';
											backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
										};
								  };
						}> | null;
					};
			  }
			| { __typename: 'Genre'; id: string }
			| {
					__typename: 'Movie';
					id: string;
					content: {
						__typename?: 'MovieContent';
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					};
			  }
			| { __typename: 'MultiStepSportEvent'; id: string }
			| { __typename: 'Offer'; id: string }
			| { __typename: 'Package'; id: string }
			| { __typename: 'Page'; id: string }
			| { __typename: 'Person'; id: string }
			| {
					__typename: 'Season';
					id: string;
					content: {
						__typename?: 'SeasonContent';
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					};
			  }
			| {
					__typename: 'Show';
					id: string;
					content: {
						__typename?: 'ShowContent';
						backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }>;
					};
			  }
			| { __typename: 'SingleStepSportEvent'; id: string }
			| { __typename: 'SportCompetitionV2'; id: string }
			| { __typename: 'SportCompetitorV2'; id: string }
			| { __typename: 'Url'; id: string };
	}>;
};

export type JustBackdrops_Movie_Fragment = {
	__typename?: 'Movie';
	content: { __typename?: 'MovieContent'; backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }> };
};

export type JustBackdrops_Season_Fragment = {
	__typename?: 'Season';
	content: { __typename?: 'SeasonContent'; backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }> };
};

export type JustBackdrops_Show_Fragment = {
	__typename?: 'Show';
	content: { __typename?: 'ShowContent'; backdrops: Array<{ __typename?: 'Backdrop'; backdropUrl: string }> };
};

export type JustBackdropsFragment =
	| JustBackdrops_Movie_Fragment
	| JustBackdrops_Season_Fragment
	| JustBackdrops_Show_Fragment;

export const JustBackdropsFragmentDoc = gql`
	fragment JustBackdrops on MovieOrShowOrSeason {
		content(country: $country, language: $language) {
			backdrops {
				backdropUrl
			}
		}
	}
`;
export const GuideEntryFragmentDoc = gql`
	fragment GuideEntry on Article {
		id
		content {
			articleType
			title
			subtitle
			paragraph1
			fullPath
			mainImageEntityBackdrop(profile: $profile, format: $format) {
				backdropUrl
			}
		}
		mainObjects: linkedObjects(priority: 1) {
			node {
				id
				__typename
				...JustBackdrops
				... on GenericTitleList {
					titles(country: $country, first: 1, sortBy: NATURAL) {
						edges {
							node {
								id
								...JustBackdrops
							}
						}
					}
				}
			}
		}
	}
	${JustBackdropsFragmentDoc}
`;
export const GetGuideArticlesDocument = gql`
	query GetGuideArticles(
		$country: Country!
		$language: Language!
		$first: Int!
		$offset: Int = 0
		$filter: ArticleFilter! = { articleTypes: [NEWS_ARTICLE] }
		$profile: BackdropProfile
		$format: ImageFormat
	) {
		articles(country: $country, first: $first, filter: $filter, offset: $offset) {
			totalCount
			edges {
				node {
					... on Article {
						...GuideEntry
					}
				}
			}
		}
	}
	${GuideEntryFragmentDoc}
`;

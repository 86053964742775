import { WebLocale } from '@/enums/web-locale';
// { getSlots, getYieldloveMaxSlotSize } from './yieldlove-helper';

export function loadGAM(webLocale: WebLocale, resolve: () => void) {
	const script = document.createElement('script');
	script.type = 'text/javascript';
	script.async = true;
	script.defer = true;
	script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
	document.body.appendChild(script);

	initSlots(webLocale, resolve);
}

const initSlots = (webLocale: WebLocale, resolve: () => void) => {
	window.googletag = window.googletag || { cmd: [] };

	// getSlots(webLocale).forEach(slot => {
	// 	// only 1 size may be sent according to Vinh
	// 	const maxSlotSize = getYieldloveMaxSlotSize(webLocale, slot.name);
	// 	window.googletag.cmd.push(function () {
	// 		window.googletag
	// 			.defineSlot(slot.gamPath, [maxSlotSize.x, maxSlotSize.y], slot.name)
	// 			.addService(window.googletag.pubads());
	// 		window.googletag.pubads().enableSingleRequest();
	// 		window.googletag.enableServices();
	// 	});
	// });

	// set as ready
	resolve();
};

import * as Types from '../../../@types/graphql-types';

import gql from 'graphql-tag';
export type GetAuthorByUrlQueryVariables = Types.Exact<{
	fullPath: Types.Scalars['String']['input'];
	first: Types.Scalars['Int']['input'];
	country: Types.Scalars['Country']['input'];
	language: Types.Scalars['Language']['input'];
	after?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;

export type GetAuthorByUrlQuery = {
	__typename?: 'Query';
	urlV2: {
		__typename?: 'Url';
		id: string;
		node:
			| { __typename?: 'Article'; id: string }
			| {
					__typename?: 'Author';
					id: string;
					content: {
						__typename?: 'AuthorContent';
						bio: string;
						clearName: string;
						imageUrl?: string | null;
						fullPath: string;
						alumniOf: Array<string>;
						brand: string;
						languagesSpoken: Array<any>;
						occupation: Array<string>;
						publications?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
						socialHandles?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
					};
					articles: {
						__typename?: 'ArticlesConnection';
						totalCount: number;
						pageInfo: {
							__typename?: 'PageInfo';
							endCursor: string;
							hasNextPage: boolean;
							hasPreviousPage: boolean;
							startCursor: string;
						};
						edges?: Array<{
							__typename?: 'ArticlesEdge';
							node: {
								__typename?: 'Article';
								id: string;
								content: {
									__typename?: 'ArticleContent';
									articleType: Types.ArticleType;
									fullPath: string;
									title?: string | null;
									subtitle?: string | null;
									snippet?: string | null;
									paragraph1?: string | null;
									synopsis?: string | null;
								};
								mainObjects: Array<{
									__typename?: 'ArticleLinkedObject';
									node:
										| { __typename?: 'Article' }
										| { __typename?: 'Author' }
										| { __typename?: 'Bundle' }
										| { __typename?: 'Device' }
										| { __typename?: 'Episode' }
										| {
												__typename: 'GenericTitleList';
												id: string;
												titles: {
													__typename?: 'GenericTitleListConnection';
													edges?: Array<{
														__typename?: 'GenericTitleListEdge';
														node:
															| {
																	__typename?: 'Movie';
																	id: string;
																	content: {
																		__typename?: 'MovieContent';
																		title: string;
																		fullPath: string;
																		posterUrl?: string | null;
																	};
															  }
															| {
																	__typename?: 'Show';
																	id: string;
																	content: {
																		__typename?: 'ShowContent';
																		title: string;
																		fullPath: string;
																		posterUrl?: string | null;
																	};
															  };
													}> | null;
												};
										  }
										| { __typename?: 'Genre' }
										| {
												__typename: 'Movie';
												id: string;
												content: {
													__typename?: 'MovieContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| { __typename?: 'MultiStepSportEvent' }
										| { __typename?: 'Offer' }
										| { __typename?: 'Package' }
										| { __typename?: 'Page' }
										| { __typename?: 'Person' }
										| {
												__typename: 'Season';
												id: string;
												content: {
													__typename?: 'SeasonContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| {
												__typename: 'Show';
												id: string;
												content: {
													__typename?: 'ShowContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| { __typename?: 'SingleStepSportEvent' }
										| { __typename?: 'SportCompetitionV2' }
										| { __typename?: 'SportCompetitorV2' }
										| { __typename?: 'Url' };
								}>;
							};
						}> | null;
					};
					titleDetailArticles: {
						__typename?: 'ArticlesConnection';
						totalCount: number;
						pageInfo: {
							__typename?: 'PageInfo';
							endCursor: string;
							hasNextPage: boolean;
							hasPreviousPage: boolean;
							startCursor: string;
						};
						edges?: Array<{
							__typename?: 'ArticlesEdge';
							node: {
								__typename?: 'Article';
								id: string;
								content: {
									__typename?: 'ArticleContent';
									articleType: Types.ArticleType;
									fullPath: string;
									title?: string | null;
									subtitle?: string | null;
									snippet?: string | null;
									paragraph1?: string | null;
									synopsis?: string | null;
								};
								mainObjects: Array<{
									__typename?: 'ArticleLinkedObject';
									node:
										| { __typename?: 'Article' }
										| { __typename?: 'Author' }
										| { __typename?: 'Bundle' }
										| { __typename?: 'Device' }
										| { __typename?: 'Episode' }
										| {
												__typename: 'GenericTitleList';
												id: string;
												titles: {
													__typename?: 'GenericTitleListConnection';
													edges?: Array<{
														__typename?: 'GenericTitleListEdge';
														node:
															| {
																	__typename?: 'Movie';
																	id: string;
																	content: {
																		__typename?: 'MovieContent';
																		title: string;
																		fullPath: string;
																		posterUrl?: string | null;
																	};
															  }
															| {
																	__typename?: 'Show';
																	id: string;
																	content: {
																		__typename?: 'ShowContent';
																		title: string;
																		fullPath: string;
																		posterUrl?: string | null;
																	};
															  };
													}> | null;
												};
										  }
										| { __typename?: 'Genre' }
										| {
												__typename: 'Movie';
												id: string;
												content: {
													__typename?: 'MovieContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| { __typename?: 'MultiStepSportEvent' }
										| { __typename?: 'Offer' }
										| { __typename?: 'Package' }
										| { __typename?: 'Page' }
										| { __typename?: 'Person' }
										| {
												__typename: 'Season';
												id: string;
												content: {
													__typename?: 'SeasonContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| {
												__typename: 'Show';
												id: string;
												content: {
													__typename?: 'ShowContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| { __typename?: 'SingleStepSportEvent' }
										| { __typename?: 'SportCompetitionV2' }
										| { __typename?: 'SportCompetitorV2' }
										| { __typename?: 'Url' };
								}>;
							};
						}> | null;
					};
			  }
			| { __typename?: 'Bundle'; id: string }
			| { __typename?: 'Device'; id: string }
			| { __typename?: 'Episode'; id: string }
			| { __typename?: 'GenericTitleList'; id: string }
			| { __typename?: 'Genre'; id: string }
			| { __typename?: 'Movie'; id: string }
			| { __typename?: 'MultiStepSportEvent'; id: string }
			| { __typename?: 'Offer'; id: string }
			| { __typename?: 'Package'; id: string }
			| { __typename?: 'Page'; id: string }
			| { __typename?: 'Person'; id: string }
			| { __typename?: 'Season'; id: string }
			| { __typename?: 'Show'; id: string }
			| { __typename?: 'SingleStepSportEvent'; id: string }
			| { __typename?: 'SportCompetitionV2'; id: string }
			| { __typename?: 'SportCompetitorV2'; id: string }
			| { __typename?: 'Url'; id: string };
	};
};

export type AuthorDataFragment = {
	__typename?: 'AuthorContent';
	bio: string;
	clearName: string;
	imageUrl?: string | null;
	fullPath: string;
	alumniOf: Array<string>;
	brand: string;
	languagesSpoken: Array<any>;
	occupation: Array<string>;
	publications?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
	socialHandles?: Array<{ __typename?: 'AuthorSpecificLink'; name: string; url: string }> | null;
};

export type ArticlesDataFragment = {
	__typename?: 'ArticlesConnection';
	totalCount: number;
	pageInfo: {
		__typename?: 'PageInfo';
		endCursor: string;
		hasNextPage: boolean;
		hasPreviousPage: boolean;
		startCursor: string;
	};
	edges?: Array<{
		__typename?: 'ArticlesEdge';
		node: {
			__typename?: 'Article';
			id: string;
			content: {
				__typename?: 'ArticleContent';
				articleType: Types.ArticleType;
				fullPath: string;
				title?: string | null;
				subtitle?: string | null;
				snippet?: string | null;
				paragraph1?: string | null;
				synopsis?: string | null;
			};
			mainObjects: Array<{
				__typename?: 'ArticleLinkedObject';
				node:
					| { __typename?: 'Article' }
					| { __typename?: 'Author' }
					| { __typename?: 'Bundle' }
					| { __typename?: 'Device' }
					| { __typename?: 'Episode' }
					| {
							__typename: 'GenericTitleList';
							id: string;
							titles: {
								__typename?: 'GenericTitleListConnection';
								edges?: Array<{
									__typename?: 'GenericTitleListEdge';
									node:
										| {
												__typename?: 'Movie';
												id: string;
												content: {
													__typename?: 'MovieContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  }
										| {
												__typename?: 'Show';
												id: string;
												content: {
													__typename?: 'ShowContent';
													title: string;
													fullPath: string;
													posterUrl?: string | null;
												};
										  };
								}> | null;
							};
					  }
					| { __typename?: 'Genre' }
					| {
							__typename: 'Movie';
							id: string;
							content: {
								__typename?: 'MovieContent';
								title: string;
								fullPath: string;
								posterUrl?: string | null;
							};
					  }
					| { __typename?: 'MultiStepSportEvent' }
					| { __typename?: 'Offer' }
					| { __typename?: 'Package' }
					| { __typename?: 'Page' }
					| { __typename?: 'Person' }
					| {
							__typename: 'Season';
							id: string;
							content: {
								__typename?: 'SeasonContent';
								title: string;
								fullPath: string;
								posterUrl?: string | null;
							};
					  }
					| {
							__typename: 'Show';
							id: string;
							content: {
								__typename?: 'ShowContent';
								title: string;
								fullPath: string;
								posterUrl?: string | null;
							};
					  }
					| { __typename?: 'SingleStepSportEvent' }
					| { __typename?: 'SportCompetitionV2' }
					| { __typename?: 'SportCompetitorV2' }
					| { __typename?: 'Url' };
			}>;
		};
	}> | null;
};

export const AuthorDataFragmentDoc = gql`
	fragment AuthorData on AuthorContent {
		bio
		clearName
		imageUrl
		fullPath
		publications {
			name
			url
		}
		socialHandles {
			name
			url
		}
		alumniOf
		brand
		languagesSpoken
		occupation
	}
`;
export const ArticlesDataFragmentDoc = gql`
	fragment ArticlesData on ArticlesConnection {
		totalCount
		pageInfo {
			endCursor
			hasNextPage
			hasPreviousPage
			startCursor
		}
		edges {
			node {
				id
				content {
					articleType
					fullPath
					title
					subtitle
					snippet
					paragraph1
					synopsis
				}
				mainObjects: linkedObjects(priority: 1) {
					node {
						... on MovieOrShowOrSeason {
							id
							__typename
							content(country: $country, language: $language) {
								title
								fullPath
								posterUrl
							}
						}
						... on GenericTitleList {
							id
							__typename
							titles(country: $country, first: 1, sortBy: NATURAL) {
								edges {
									node {
										id
										content(country: $country, language: $language) {
											title
											fullPath
											posterUrl
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
export const GetAuthorByUrlDocument = gql`
	query GetAuthorByUrl($fullPath: String!, $first: Int!, $country: Country!, $language: Language!, $after: String) {
		urlV2(fullPath: $fullPath) {
			id
			node {
				id
				... on Author {
					id
					content(country: $country, language: $language) {
						...AuthorData
					}
					articles(country: $country, first: $first, after: $after, filter: { articleTypes: NEWS_ARTICLE }) {
						...ArticlesData
					}
					titleDetailArticles: articles(
						country: $country
						first: $first
						after: $after
						filter: { articleTypes: [TITLE_DETAIL_ARTICLE, REVIEW_ARTICLE] }
					) {
						...ArticlesData
					}
				}
			}
		}
	}
	${AuthorDataFragmentDoc}
	${ArticlesDataFragmentDoc}
`;

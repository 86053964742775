const streamingChartCountries = [
	'GB',
	'US',
	'CA',
	'AU',
	'DE',
	'AT',
	'CH',
	'FR',
	'IT',
	'ES',
	'AR',
	'MX',
	'TR',
	'PT',
	'BR',
	'IN',
	// waiting for translations.
	// 'EG',
	// 'PL',
];
const streamingChartLandingPageCountries = [
	'AR',
	'AU',
	'BR',
	'CA',
	'ES',
	'EG',
	'FR',
	'DE',
	'IN',
	'IT',
	'MX',
	'PO',
	'PL',
	'TR',
	'US',
	'GB',
];

const reverseObjectTypeQueryMapping = {
	movies: 'MOVIE',
	shows: 'SHOW',
	seasons: 'SHOW_SEASON',
};

const reverseCategoryQueryMapping = {
	daily: 'DAILY_POPULARITY_SAME_CONTENT_TYPE',
	weekly: 'WEEKLY_POPULARITY_SAME_CONTENT_TYPE',
	monthly: 'MONTHLY_POPULARITY_SAME_CONTENT_TYPE',
};

export {
	streamingChartCountries,
	streamingChartLandingPageCountries,
	reverseObjectTypeQueryMapping,
	reverseCategoryQueryMapping,
};

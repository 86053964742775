
import Vue from 'vue';
import { Component, Prop, Watch, Emit } from 'vue-property-decorator';
import { Route } from 'vue-router';

import YouTubePlayer from 'youtube-player';

import { YoutubePlayerOptions } from '@/interfaces/components/youtube-player';

import { PlayerIcon } from '@/enums/player-icon';

import VideoPlayerWrapper from './VideoPlayerWrapper.vue';
import { TrailerClip } from '@/interfaces/trailerClip';

enum YoutubePlayerStatus {
	UNSTARTED = '-1',
	ENDED = 0,
	PLAYING = 1,
	PAUSED = 2,
	BUFFERING = 3,
	CUED = 5,
}

enum YoutubePlayerEvent {
	READY = 'ready',
	STATE_CHANGE = 'stateChange',
}

@Component({
	name: 'YoutubePlayerGraphql',
	components: { VideoPlayerWrapper },
})
export default class YoutubePlayerGraphql extends Vue {
	@Prop({ required: true }) clip: TrailerClip;
	@Prop({ default: '' }) source: string;
	@Prop({ required: true }) options: YoutubePlayerOptions;
	@Prop({ default: '100%' }) width: number | string | null;
	@Prop({ default: null }) height: number | string | null;
	@Prop({ default: false }) isActive: boolean;
	@Prop() activeRoute: Route;
	@Prop({ default: false }) isBackdrop: boolean; // Show preview image on background while video playing
	@Prop({ default: null }) playIconType: PlayerIcon | null;
	@Prop({ required: false, default: false }) isDisneyPlusOffer: boolean;

	@Watch('isActive')
	onActiveChange() {
		if (this.player && !this.isActive) {
			this.player.pauseVideo();
		}
	}

	@Watch('activeRoute')
	onActiveRouteChange() {
		this.destroyPlayer();
	}

	@Emit('setActiveClipId')
	setActiveClipId(clipId: string | null) {
		return clipId;
	}

	player: any = null;
	playerEventListeners: any[] = [];

	get previewImageUrl() {
		return this.clip ? `https://img.youtube.com/vi/${this.clip.externalId}/mqdefault.jpg` : '';
	}

	private get playerOptions(): YoutubePlayerOptions {
		return {
			autohide: 1,
			iv_load_policy: 3,
			modestbranding: 1,
			rel: 0,
			mute: 0,
			...this.options,
		};
	}

	get playerId() {
		return `youtube-player-${this.clip.externalId}-${this.source}`;
	}

	play() {
		if (this.player) {
			this.player.playVideo();
		} else {
			this.player = YouTubePlayer(this.playerId, {
				...(this.width ? { width: this.width } : {}),
				...(this.height ? { height: this.height } : {}),
				videoId: this.clip.externalId,
				playerVars: this.playerOptions,
			});

			this.playerEventListeners.push(
				this.player.on(YoutubePlayerEvent.READY, () => {
					this.setActiveClipId(this.clip.externalId);
					this.player.playVideo();
				})
			);

			this.playerEventListeners.push(
				this.player.on(YoutubePlayerEvent.STATE_CHANGE, (event: any) => {
					this.onStateChange(event);
				})
			);
		}
	}

	onStateChange(event: { data: YoutubePlayerStatus }) {
		if (event.data === YoutubePlayerStatus.PLAYING) {
			this.setActiveClipId(this.clip.externalId);
			this.$emit('onPlaying', this.clip);
		}

		if (event.data === YoutubePlayerStatus.PAUSED) {
			this.$emit('onPaused', this.clip);
		}

		if (event.data === YoutubePlayerStatus.ENDED) {
			this.setActiveClipId(null);
			this.$emit('onEnded', this.clip);
		}

		if (event.data === YoutubePlayerStatus.BUFFERING) {
			this.$emit('onBuffering', this.clip);
		}
	}

	destroyPlayer() {
		if (this.playerEventListeners?.length) {
			this.playerEventListeners.forEach(playerEventListener => {
				this.player.off(playerEventListener);
			});
		}

		if (this.isActive) {
			this.setActiveClipId(null);
		}

		if (this.player) {
			this.player.destroy();
			this.player = null;
		}
	}

	mounted() {
		if (this.isActive) {
			setTimeout(() => {
				this.play();
			}, 0);
		}
	}
}

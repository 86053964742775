
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { getScorings, getVotesLabel, ScoringProvider, getRottenTomatoesScoreIcon } from '@/helpers/scoring-helper';
import { TrackingHelper } from '@/helpers/tracking/tracking-helper';
import { ExternalIds, Scoring } from '@/@types/graphql-types';
import { SnowplowContext } from '@/helpers/tracking/providers';
import { namespace } from 'vuex-class';
import { numberToCompactString } from '@/helpers/NumberFormat';

const Language = namespace('language');

// TODO Make sure it works not only with IMDB scoring + votes
@Component({
	name: 'TitleScoreList',
})
export default class TitleScoreList extends Vue {
	@Prop({ required: true }) scores: Scoring;
	@Prop({ default: () => ({}) }) externalIds: ExternalIds;
	@Prop({ default: () => [] }) excluded?: ScoringProvider[];
	@Prop({ default: true }) includeLinks?: boolean;
	@Prop({ default: false }) imdbOnly: boolean;
	@Prop({ default: true }) lazy: boolean;
	@Prop({ default: false }) showVotes: boolean;
	@Prop({ default: () => [] }) additionalContexts: SnowplowContext[];
	@Prop({ default: null }) jwVotes: number | null;
	@Prop({ default: true }) showJwVotes: boolean;
	@Prop({ default: false }) isPosterDetail: boolean;

	@Language.Getter country: string;

	localJwVotes: number | null = null;

	async mounted() {
		this.localJwVotes = this.jwVotes;
		const observer = new IntersectionObserver(this.handleIntersection, { root: null, threshold: 0.5 });
		if (this.$refs.rootElement !== undefined) {
			observer.observe(this.$refs.rootElement as Element);
		}
	}

	private handleIntersection(entries: IntersectionObserverEntry[]) {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				this.localJwVotes = this.jwVotes;
			} else {
			}
		});
	}
	showRating(score: any) {
		if (score.name === 'JustWatch Rating' && this.computedJwVotes > 30) return true;
		else if (score.name !== 'JustWatch Rating') return true;
	}

	get computedJwVotes() {
		if (this.localJwVotes === null) return 0;
		return this.localJwVotes;
	}

	formatVotes(votes: number | null) {
		if (!votes || votes < 30) return '';
		if (votes >= 10000) return `(${numberToCompactString(votes, 0)})`;
		return `(${numberToCompactString(votes, 1)})`;
	}

	get filteredScoring() {
		let filteredScoring = this.scoring.filter(score => !!score.score && this.showRating(score));

		if (this.imdbOnly) {
			return filteredScoring.filter(scoring => scoring.provider === ScoringProvider.IMDB);
		}

		return filteredScoring;
	}

	get scoring() {
		const scoring = getScorings(this.scores, this.externalIds, this.excluded, this.includeLinks);

		return scoring.map(score => {
			if (score.provider === ScoringProvider.IMDB) {
				const votes = this.scores.imdbVotes || 0;
				const votesLabel = getVotesLabel(votes);

				if (votesLabel && this.showVotes) {
					score.append = ` (${votesLabel})`;
				}
			}
			if (score.provider === ScoringProvider.TMDB && this.computedJwVotes > 30) {
				const score = Math.round((this.scores.jwRating ?? 0) * 100);
				return {
					append: '%',
					decimals: 0,
					filterDisplay: false,
					image: '/appassets/img/jw-icon.png',
					max: 100,
					min: 0,
					name: 'JustWatch Rating',
					provider: ScoringProvider.JUSTWATCH,
					queryName: 'justwatchRating',
					score: score <= 0 ? '0' : score,
					step: 1,
					value: 0,
					link: '',
				};
			}
			if (score.provider === ScoringProvider.ROTTEN_TOMATOES) {
				score.image = getRottenTomatoesScoreIcon(Number(score.score), !!this.scores.certifiedFresh);
			}
			return score;
		});
	}

	onRatingClick($event: Event, score: any) {
		$event.preventDefault();
		$event.stopPropagation();
		$event.stopImmediatePropagation();

		TrackingHelper.trackEvent(
			'userinteraction',
			{
				action: 'rating_click',
				label: score.name,
			},
			this.additionalContexts
		);

		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX
		// trackTitleDetailRedesignNewBuyBoxExp({ action: 'click', label: 'rating_click' });
		// TITLE_DETAIL_REDESIGN_NEW_BUYBOX

		let scoreLink = score.link;

		// Reconstruct IMDb link to use current IMDb website URL formatting
		if (this.$route.name === 'app.detail.show.season' && score.name === 'IMDB') {
			const imdbId = this.externalIds?.imdbId;
			let idInPath = imdbId;
			if (imdbId?.includes(':')) {
				const [id] = imdbId.split(':', 2);
				idInPath = id;
			}
			scoreLink = `https://www.imdb.com/title/${idInPath}?ref_=ref_ext_justwatch`;
		}

		window.open(scoreLink);
	}
}

import { WebLocale } from '@/enums/web-locale';
import { adSlots } from './ad-helper';

declare global {
	interface Window {
		yieldlove_site_id: string;
		yieldlove_prevent_autostart: boolean;
	}
}

type GoogleSlot = {
	name: string;
	path: string;
	//gamPath: string;
	sizes: number[][];
};

const slots = {} as Record<string, GoogleSlot[]>;

function getSlots(webLocale: WebLocale) {
	// defaulting to 420px because 50% of the all users are coming around this width taken from analytics
	const innerWidth = process.client ? window.innerWidth : 420;
	if (!slots[webLocale]) {
		slots[webLocale] = adSlots
			.filter(slotItem => !!slotItem.path)
			.map(slotItem => {
				return {
					path: getSlotPath(webLocale, slotItem.path as string),
					//gamPath: slotItem.gamPath,
					name: slotItem.name.replace('{WEBLOCALE}', webLocale),
					sizes: slotItem.sizes
						.filter(size => !size.breakpoint || size.breakpoint <= innerWidth)
						.map(size => [size.x, size.y]),
				};
			});
	}
	return slots[webLocale];
}

export function getSlotPath(webLocale: WebLocale, path: string) {
	return path?.replace('{WEBLOCALE}', webLocale);
	// STOP using YL ADunits (To be removed once we are fully migrated to our GAM)
	//.replace('/21679119891/', `/53015287,21679119891/justwatch.com/justwatch.com_${webLocale}/`);
}
/**
 * get biggest slot size placeholder.
 * whatever slot added
 */
const getYieldloveMaxSlotSize = (webLocale: WebLocale, slotId: string) => {
	const slot = getSlots(webLocale).find(slot => slot.name === slotId);
	let size: number[] | undefined;
	if (slot && slot.sizes) {
		size = slot.sizes.reduce(
			(root: number[], item: number[]) => {
				return root[0] < item[0] ? item : root;
			},
			[0, 0]
		);
	}
	return {
		x: (size || [])[0],
		y: (size || [])[1],
	};
};

/**
 * All yieldlove_v1 locales used the first version of the yieldlove script
 * and yieldlove_v2 uses the second version.
 * the plan here is to see how v2 is performimg before migrating all the v1 locales to it.
 */

const yieldlove_v1 = [
	WebLocale.au,
	WebLocale.ca,
	WebLocale.ch,
	WebLocale.es,
	WebLocale.fr,
	WebLocale.it,
	WebLocale.pt,
	WebLocale.uk,
	WebLocale.us,
	// migrated from v2 to v1
	WebLocale.de,
	WebLocale.co,
	WebLocale.tr,
	WebLocale.ar,
	WebLocale.at,
	WebLocale.dk,
	WebLocale.fi,
	WebLocale.no,
	WebLocale.se,
	WebLocale.mx,
	WebLocale.in,
	WebLocale.br,
];
const yieldlove_v2 = [] as string[];

const onScriptLoad = (script: HTMLScriptElement) => {
	return new Promise((res, rej) => {
		script.onload = () => {
			res(true);
		};
		script.onerror = err => {
			rej(err);
		};
	});
};

const YL_MAX_RETRIES = 20;
const YL_RETRY_DELAY = 200;
// first 5 retries every 200ms, second 5 retries every 400ms,... Total of 10s

// It looks like yieldLove is failing because window.YLHH.bidder is unavailable - this should only call yieldlove bidder when available or wait and retry
function checkYLBidderAvailability(retryCount: number = 0, resolve: () => void) {
	if (window.YLHH && window.YLHH.bidder) {
		resolve();
	} else if (retryCount < YL_MAX_RETRIES) {
		setTimeout(
			() => checkYLBidderAvailability(retryCount + 1, resolve),
			YL_RETRY_DELAY * Math.ceil((retryCount + 1) / 5)
		);
	} else {
		// if we reach the max retries, we should resolve the promise and the error will be thrown in the [ad-helper] when we check for window.YLHH.bidder
		resolve();
	}
}

const loadAdYieldlove = async (webLocale: WebLocale, resolve: () => void, reject: () => void) => {
	window.yieldlove_site_id = 'justwatch.com_' + webLocale;
	window.yieldlove_prevent_autostart = true;

	// include yieldlove bidder
	const c = document.getElementsByTagName('script')[0];
	const yieldloveScript = document.createElement('script');

	yieldloveScript.type = 'text/javascript';
	yieldloveScript.src = yieldlove_v1.includes(webLocale)
		? 'https://cdn-a.yieldlove.com/yieldlove-bidder.js?justwatch.com_' + webLocale
		: 'https://cdn-a.yieldlove.com/v2/yieldlove.js?justwatch.com';
	yieldloveScript.async = true;
	yieldloveScript.defer = true;

	c.parentNode!.insertBefore(yieldloveScript, c);

	window.googletag = window.googletag || {};
	window.googletag.cmd = window.googletag.cmd || [];

	// dfp vendor
	const googleAdServiceScript = document.createElement('script');
	googleAdServiceScript.type = 'text/javascript';
	googleAdServiceScript.async = true;
	googleAdServiceScript.defer = true;
	if (yieldlove_v2.includes(webLocale)) {
		googleAdServiceScript.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
	} else {
		googleAdServiceScript.src = 'https://www.googletagservices.com/tag/js/gpt.js';
	}
	document.body.appendChild(googleAdServiceScript);

	try {
		await Promise.all([onScriptLoad(googleAdServiceScript), onScriptLoad(yieldloveScript)]);
	} catch (er) {
		import('@/helpers/sentry-helper').then(({ captureMessageForSentry }) => {
			captureMessageForSentry(`[yieldlove-helper.ts YIELDLOVE]: Failed to load YL Scripts`);
		});
		reject();
		return;
	}

	initSlots(webLocale, () => checkYLBidderAvailability(0, resolve));
};

const initSlots = (webLocale: WebLocale, resolve: () => void) => {
	window.googletag.cmd.push(() => {
		getSlots(webLocale).forEach(slot => {
			// only 1 size may be sent according to Vinh
			const maxSlotSize = getYieldloveMaxSlotSize(webLocale, slot.name);
			// window.googletag.defineSlot(slot.path, slot.sizes, slot.name).addService(window.googletag.pubads());
			window.googletag
				.defineSlot(slot.path, [maxSlotSize.x, maxSlotSize.y], slot.name)
				.addService(window.googletag.pubads());
		});
		window.googletag.pubads().enableSingleRequest();
		if (yieldlove_v2.includes(webLocale)) {
			window.googletag.pubads().disableInitialLoad(); /// add only for v2
		}
		window.googletag.enableServices();

		// set as ready
		resolve();
	});
};
const yieldloveWebLocales = [...yieldlove_v1, ...yieldlove_v2];

export { getYieldloveMaxSlotSize, loadAdYieldlove, yieldloveWebLocales, getSlots };
